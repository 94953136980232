import React, { useEffect, useState } from 'react';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';

import { RESULTS_INFO_CONTAINER_DIMS } from 'pages/ResultsOverview/constants';
import { TraitCategory } from 'pages/ResultsOverview/types';

const containerWidth = RESULTS_INFO_CONTAINER_DIMS.width;

interface ResultsInfoCarouselProps {
  activeTab: TraitCategory;
  children: React.ReactNode;
}

const ResultsInfoCarousel = ({ activeTab, children }: ResultsInfoCarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Reset the index when activeTab changes
  useEffect(() => {
    setCurrentIndex(0);
  }, [activeTab]);

  // Ensure children is always an array
  const childrenArray = React.Children.toArray(children);

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < childrenArray.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const isLeftDisabled = currentIndex === 0;
  const isRightDisabled = currentIndex === childrenArray.length - 1;

  return (
    <div className="w-screen overflow-hidden">
      {/* Reasults Info Containers */}
      <div
        className="flex flex-row mb-12 space-x-2 transition ease-out delay-75 w-max"
        style={{
          // Center the leftmost info box and shift left based on its width, spacing, and current index
          transform: `translateX(calc(50vw - ${containerWidth / 2}px - ${currentIndex * (containerWidth + 8)}px))`,
        }}
      >
        {childrenArray.map((child) => child)}
      </div>

      {/* Carousel Pagination */}
      <div className="flex items-center justify-center space-x-3">
        <button
          className={`${isLeftDisabled ? 'border-gray-500 text-gray-500' : 'border-white text-white'} bg-transparent border p-4 rounded-full`}
          type="button"
          onClick={handlePrev}
          disabled={isLeftDisabled}
        >
          <SlArrowLeft className="text-xs font-bold" aria-label="Previous Slide" />
        </button>

        {childrenArray.map((_, index) => (
          <div className={`${currentIndex === index ? 'bg-white' : 'bg-gray-500'} w-2 h-2 rounded-full`} key={index} />
        ))}

        <button
          className={`${isRightDisabled ? 'border-gray-500 text-gray-500' : 'border-white text-white'} bg-transparent border p-4 rounded-full`}
          type="button"
          onClick={handleNext}
          disabled={isRightDisabled}
        >
          <SlArrowRight className="text-xs font-bold" aria-label="Next Slide" />
        </button>
      </div>
    </div>
  );
};

export default ResultsInfoCarousel;
