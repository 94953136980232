import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { UserProvider } from 'context/userContext';
import Layout from 'components/Layout';
import { AppInstructions } from 'pages/AppInstruction';
import Profile from 'pages/Profile';
import Questions from 'pages/Questions';
import SectionBreak from 'pages/SectionBreak';
import ResultsIntro from 'pages/ResultsIntro';
import ThankYou from 'pages/ThankYou';
// import PersonalityProfile from 'pages/PersonailityProfile';
// import InterestsProfile from 'pages/InterestsProfile';
import ResultsOverview from 'pages/ResultsOverview/ResultsOverview';
import NotFound from 'components/NotFound';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/:companyId"
          element={
            <UserProvider>
              <Layout />
            </UserProvider>
          }
        >
          <Route index element={<AppInstructions />} />
          <Route path="profile" element={<Profile />} />
          <Route path="questions/personality" element={<Questions id="personality" title="Your Personality" icon="/icon/personality_head.png" />} />
          <Route
            path="questions/interests/instructions"
            element={
              <SectionBreak
                title="Your values"
                body={['What is important to you when it comes to work?', 'Tell us what really matters to you so we can find teams that are a good fit ...']}
                icon="/icon/toolbox.png"
              />
            }
          />
          <Route path="questions/interests" element={<Questions id="interests" title="What type of jobs might you enjoy doing?" icon="/icon/toolbox.png" />} />

          <Route
            path="questions/values/instructions"
            element={
              <SectionBreak
                title="Your values"
                body={['What is important to you when it comes to work?', 'Tell us what really matters to you so we can find teams that are a good fit ...']}
                icon="/icon/head-heart.png"
              />
            }
          />

          <Route path="questions/values" element={<Questions id="values" title="Your values" icon="/icon/head-heart.png" />} />

          <Route
            path="questions/customer/instructions"
            element={
              <SectionBreak
                title="Last Question Section!"
                body={['Just a few more questions to pin down what makes a good employee fit']}
                icon="/icon/stopwatch.png"
              />
            }
          />

          <Route path="questions/customer" element={<Questions id="customer" title="Last Question Section!" icon="/icon/stopwatch.png" />} />
          <Route path="results-intro" element={<ResultsIntro />} />
          <Route path="results-overview" element={<ResultsOverview />} />
          {/* <Route path="personality-results" element={<PersonalityProfile />} /> */}
          {/* <Route path="interests-results" element={<InterestsProfile />} /> */}
          <Route path="thank-you" element={<ThankYou />} />
        </Route>
        <Route path="not-found" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
