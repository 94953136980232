import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../context/userContext';

interface LeftOffDialogProps {
  setLeftOffUrl: Dispatch<SetStateAction<string | undefined>>;
  leftOffUrl: string | undefined;
  companyId: string;
}
const LeftOffDialog = ({ setLeftOffUrl, leftOffUrl, companyId }: LeftOffDialogProps) => {
  const navigate = useNavigate();
  const { saveUserAnswers } = useUser();

  const navigateToLeftOff = () => {
    if (leftOffUrl) {
      navigate(leftOffUrl);
    }
  };

  const startAgain = () => {
    if (leftOffUrl) {
      setLeftOffUrl(undefined);
      navigate(`/${companyId}/profile`);
      saveUserAnswers({}, {});
    }
  };

  return (
    <div>
      <button className="bg-yellow-500 text-black 	p-2	uppercase rounded-lg font-semibold text-md" type="button" onClick={navigateToLeftOff}>
        Continue where you left off
      </button>
      <div className="p-4">or </div>
      <button className="bg-yellow-500 text-black 	p-2	uppercase rounded-lg font-semibold text-md" type="button" onClick={startAgain}>
        Start again
      </button>
    </div>
  );
};

export { LeftOffDialog };
