import { InfoBoxDescription } from 'pages/ResultsOverview/types';

interface ResultsInfoListProps {
  resultsData: {
    name: string;
    value: number;
    fill: string;
    description: InfoBoxDescription;
  }[];
}

const ResultsInfoList = ({ resultsData }: ResultsInfoListProps) => (
  <div className="w-full space-y-4">
    {resultsData.map((data, index) => (
      <div key={index} className="flex items-center space-x-4 text-sm">
        {/* Right-Aligned Trait Name */}
        <span className="w-48 font-semibold text-right text-white ">{data.name}</span>
        {/* Bar Container */}
        <div className="box-content w-1/2 h-6 p-[2px] rounded-full bg-bar">
          <div
            className="flex items-center h-6 px-2 font-bold text-white rounded-full"
            style={{
              width: `${data.value}%`,
              backgroundColor: data.fill,
              display: `${Number.isNaN(data.value) ? 'none' : ''}`,
            }}
          />
        </div>
      </div>
    ))}
  </div>
);

export default ResultsInfoList;
