import React from 'react';

import { TraitCategory } from 'pages/ResultsOverview/types';
import { computeCategoryTraits, createOutputDescription } from 'pages/ResultsOverview/utils';
import { resultsDimensions } from 'pages/ResultsOverview/constants';
import { capitalizeTitle } from 'utils';
import { AnswersItem, Question } from '__generated__/graphql';

interface PrintableResultsProps {
  answers: AnswersItem;
  questions: Question;
  contentRef: React.Ref<HTMLDivElement>;
}

const PrintableResults = ({ contentRef, answers, questions }: PrintableResultsProps) => (
  <div
    ref={contentRef}
    className="hidden"
    style={{
      fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
      padding: '0 48px',
    }}
  >
    <h1 style={{ margin: '48px 0' }}>Your Survey Results</h1>
    {Object.values(TraitCategory).map((category) => {
      const computedTraits = computeCategoryTraits(category, answers, questions[category] || []);

      const resultsData = resultsDimensions
        .filter(({ key }) => key in computedTraits)
        .map(({ key }) => {
          // Create a lookup map to avoid redundant searches
          const resultsDimensionMap = new Map(resultsDimensions.map((dimension) => [dimension.key, dimension]));
          const resultsDimension = resultsDimensionMap.get(key);

          const name = resultsDimension?.name ?? '';
          const value = computedTraits[key as keyof typeof computedTraits];
          const description = createOutputDescription(name, resultsDimension?.description ?? [''], value);

          return {
            name,
            description,
          };
        });

      return (
        <div key={category} style={{ marginBottom: '48px' }}>
          <h2 style={{ marginBottom: '32px' }}>{capitalizeTitle(category)}</h2>
          {resultsData.map((data) => (
            <div key={data.name}>
              <h3 style={{ color: '#666', marginBottom: '0', fontWeight: 'normal' }}>{data.name}</h3>
              <p style={{ marginTop: '8px' }}>{data.description}</p>
            </div>
          ))}
        </div>
      );
    })}
  </div>
);

export default PrintableResults;
