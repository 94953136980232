import { capitalizeTitle } from 'utils';

interface ResultsInfoTabsProps {
  tabNames: string[];
  activeTab: string;
  onClick: (tabName: string) => void;
}

const ResultsInfoTabs = ({ tabNames, activeTab, onClick }: ResultsInfoTabsProps) => (
  <div className="mb-16">
    <div className="flex justify-center">
      {tabNames.map((tabName) => (
        <div
          className={`${activeTab === tabName ? 'border-b-2 border-white text-white' : 'text-gray-500'} inline font-medium w-44 py-2 text-center`}
          key={tabName}
        >
          <span className="cursor-pointer" onClick={() => onClick(tabName)}>
            {capitalizeTitle(tabName)}
          </span>
        </div>
      ))}
    </div>
    <hr className="w-full border-gray-500" style={{ height: '1px' }} />
  </div>
);

export default ResultsInfoTabs;
