import { AnswersItem, Maybe, QuestionItem } from '__generated__/graphql';
import { meanOf } from 'utils';
import { CATEGORY_TRAITS, InfoBoxDescription, TraitCategory } from './types';

/*
 * Computes the values for all traits in a given category based on the user's answers and questions.
 *
 * @param traitCategory - The category of traits to compute (e.g., 'personality', 'interests', 'values').
 * @param answers - The user's answers (can be null or undefined).
 * @param questions - A list of questions (can be null or undefined).
 * @returns An object where the keys are trait names and the values are the computed values for those traits.
 */
export const computeCategoryTraits = (
  traitCategory: TraitCategory, // The category of traits to compute
  answers: Maybe<AnswersItem>, // The user's answers
  questions: Maybe<QuestionItem>[], // A list of questions
): Record<string, number> => {
  const traits = CATEGORY_TRAITS[traitCategory];

  // Use `reduce` to accumulate a new object with computed trait values in the specified order
  return traits.reduce<Record<string, number>>(
    (computedTraits, trait) => ({
      ...computedTraits,
      [trait]: meanOf(trait, answers, questions),
    }),
    {},
  );
};

export const createOutputDescription = (traitName: string, description: InfoBoxDescription, traitValue: number): string => {
  if (Number.isNaN(traitValue)) return 'No data';
  if (traitValue === 50) return `You are well balanced in terms of ${traitName}`;

  const descArray = Array.isArray(description) ? description : traitValue > 50 ? description.positive : description.negative;
  return descArray.join(' ');
};
