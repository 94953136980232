import { ApolloProvider } from '@apollo/client';
import { client } from './api';
import Routes from './routes';

export const App = () => {
  return (
    <div className="bg-slate-600 h-full">
      <ApolloProvider client={client}>
        <Routes />
      </ApolloProvider>
    </div>
  );
};
