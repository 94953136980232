// import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal } from 'react';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';

interface ResultsInfoCarouselPaginationProps {
  pageCount: number;
  currentIndex: number;
  setCurrentIndex: (arg: number) => void;
}

const ResultsInfoCarouselPagination = ({ pageCount, currentIndex, setCurrentIndex }: ResultsInfoCarouselPaginationProps) => {
  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < pageCount - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const isLeftDisabled = currentIndex === 0;
  const isRightDisabled = currentIndex === pageCount - 1;

  return (
    <div className="flex items-center justify-center space-x-5 md:space-x-3">
      <button
        className={`${isLeftDisabled ? 'border-gray-500 text-gray-500' : 'border-white text-white'} bg-transparent border p-4 rounded-full hidden sm:block`}
        type="button"
        onClick={handlePrev}
        disabled={isLeftDisabled}
      >
        <SlArrowLeft className="text-xs font-bold" aria-label="Previous Slide" />
      </button>

      {Array.from({ length: pageCount }).map((_, index) => (
        <div className={`${currentIndex === index ? 'bg-white' : 'bg-gray-500'} w-3 h-3 rounded-full md:w-2 md:h-2`} key={index} />
      ))}

      <button
        className={`${isRightDisabled ? 'border-gray-500 text-gray-500' : 'border-white text-white'} bg-transparent border p-4 rounded-full hidden sm:block`}
        type="button"
        onClick={handleNext}
        disabled={isRightDisabled}
      >
        <SlArrowRight className="text-xs font-bold" aria-label="Next Slide" />
      </button>
    </div>
  );
};

export default ResultsInfoCarouselPagination;
