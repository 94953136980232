import { useState } from 'react';
import Loading from 'components/Loading';
import { useUser } from '../context/userContext';
import ThankYou from './ThankYou';

const ResultsIntro = () => {
  const [completeProcess, setCompleteProcess] = useState(false);
  const { saveCompleteProcess, saveAnswersLoading, clear } = useUser();

  const onComplete = () => {
    saveCompleteProcess();
    setCompleteProcess(true);
    clear();
  };

  if (saveAnswersLoading) {
    return (
      <div className="h-5/6	flex flex-col items-center justify-center text-center text-white gap-20">
        <div className="text-white text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">
          Please wait while we process your results
        </div>
        <p className="text-white">Thanks for waiting.</p>
        <Loading isComponent={true} />
      </div>
    );
  }

  if (completeProcess) {
    return <ThankYou />;
  }

  return (
    <div className="h-5/6	flex flex-col items-center justify-center text-center text-white gap-20">
      {!saveAnswersLoading && !completeProcess ? (
        <>
          <div className="ps-3">
            <div className="text-white text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">Almost done</div>
            <p className="text-white">Thanks for taking our test!</p>
          </div>
          <img className="w-20 h-20" src="/icon/puzzle_head.png" alt="puzzle head" />
          <p className="text-white">Please click submit to send your results</p>
          <button className="bg-yellow-500 text-black p-2	uppercase rounded-lg font-semibold text-md " type="button" onClick={() => onComplete()}>
            Submit
          </button>
        </>
      ) : null}
    </div>
  );
};

export default ResultsIntro;
