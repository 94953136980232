import { ReactNode } from 'react';

import { RESULTS_INFO_CONTAINER_DIMS } from 'pages/ResultsOverview/constants';

interface ResultsInfoContainerProps {
  children: ReactNode;
}

const ResultsInfoContainer = ({ children }: ResultsInfoContainerProps) => (
  <div
    className="flex flex-col items-center px-8 rounded-3xl bg-infoBox"
    style={{ width: RESULTS_INFO_CONTAINER_DIMS.width, height: RESULTS_INFO_CONTAINER_DIMS.height }}
  >
    {children}
  </div>
);

export default ResultsInfoContainer;
