import { Company } from '__generated__/graphql';
import { SelectField } from './SelectField';

interface RegionSelectFieldProps {
  label: string;
  name: string;
  disabled?: boolean;
  testId?: string;
  searchable?: boolean;
  company: Company | null | undefined;
}

const RegionSelectField = ({ label, name, disabled = false, searchable, testId, company }: RegionSelectFieldProps) => {
  const options = company?.commute_time_configs?.map((s, index) => {
    return { label: s?.address_destination || '', value: s?.id || '', id: index.toString() };
  });

  return (
    <SelectField
      isRequired={true}
      loading={false}
      searchable={searchable}
      testId={testId}
      label={label}
      name={name}
      options={options || []}
      disabled={disabled}
    />
  );
};

export { RegionSelectField };
