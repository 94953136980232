import { useNextStep } from 'hooks/useStep';

const ThankYou = () => {
  const { nextStep } = useNextStep();

  return (
    <div className="h-5/6 flex flex-col items-center justify-between text-center text-white ">
      <div className="text-white text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">Thank you for your time!</div>
      <p className="text-white">We will be in touch soon to share the next steps with you.</p>
      <button className="bg-yellow-500 text-black mt-10 p-2	uppercase rounded-lg font-semibold text-md " type="button" onClick={() => nextStep()}>
        Return to home
      </button>
    </div>
  );
};

export default ThankYou;
