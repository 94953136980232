export const RESULTS_INFO_CONTAINER_DIMS = { width: 650, height: 450 };

export const INFO_LIST_COLORS = ['#489982', '#d3f1f1', '#A36A00', '#F8E8B1', '#5773B3', '#9C56BE', '#F05454'];

export const personalityDimensions = [
  {
    key: 'extraversion',

    name: 'Extraversion',
    description: {
      positive: ['You are outgoing and talkative.', 'Some people might think you can be a bit of (sings loudly) an attention seeker.'],
      negative: ["You're happy with your own company and thoughts. Bliss.", 'Some great big loudmouths might describe you as quiet and thoughtful.'],
    },
  },
  {
    key: 'intellect_imagination',

    name: 'Openness to experience',
    description: {
      positive: ['You are often curious and interested in new ideas.', 'You prefer things to be different rather than the same allllll the time zzzzzz.'],
      negative: [
        "You prefer things that are tried and tested. That's not boring, right?",
        "You like people who don't give up.",
        "Some people might describe you as 'small minded' - HOW DARE THEY!",
      ],
    },
  },
  {
    key: 'agreeableness',

    name: 'Friendliness',
    description: {
      positive: ['You trust and help other people when you can.'],
      negative: ['Some people might think you are competitive. They are losers.', 'Some people might think you are argumentative. They = wrong. You = right.'],
    },
  },
  {
    key: 'conscientiousness',

    name: 'Conscientiousness',
    description: {
      positive: [
        'You are organized and dependable in a very messy world.',
        'Some people would say you are very disciplined and have a sense of duty.',
        "You'd rather know what's coming than for someone to surprise you.",
      ],
      negative: ['People say you are flexible and spontaneous.', 'Some people might wrongly think of you as a bit unreliable. Whatever. What time is it?'],
    },
  },
  {
    key: 'neuroticism',

    name: 'Neuroticism',
    description: {
      positive: ["You're enthusiastic and dynamic - full of energy.", 'Sometimes you get angry and can worry too much.'],
      negative: ["You're calm and relaxed - 'chilled out'.", "Some people might think you're a bit distant."],
    },
  },
];

export const vocationalInterests = [
  {
    key: 'artistic',

    name: 'Artistic',
    description: [
      "Deep down you're an artist! You enjoy work that helps you express yourself such as acting, music, art, and design.",
      'They like creativity in their work and work that can be done without following a set of rules.',
    ],
  },
  {
    key: 'conventional',

    name: 'Conventional',
    description: [
      'You like work with a bit of risk and lots of reward where you can be in control some of the time.',
      'You take action quickly rather than think about things for too long.',
      'You influence people positively and will make a good leader.',
    ],
  },
  {
    key: 'enterprising',

    name: 'Enterprising',
    description: [
      'You like work with a bit of risk and lots of reward where you can be in control some of the time.',
      'You take action quickly rather than think about things for too long.',
      'You influence people positively and will make a good leader.',
    ],
  },
  {
    key: 'investigative',
    name: 'Investigative',
    description: [
      'You enjoy work that has to do with ideas and thinking rather than physical activity or leading people.',
      'To solve problems at work you will search for facts and figure out how to make things better.',
    ],
  },
  {
    key: 'realistic',
    name: 'Realistic',
    description: [
      "You're hands on - not happy at a desk.",
      "You'd enjoy working with plants and animals or real-world materials like wood, tools, and machinery.",
      'Working outside is quite likely to make you really happy.',
    ],
  },
  {
    key: 'social',

    name: 'Extraversion',
    description: [
      'You like working with others to help them learn and grow.',
      'You enjoy working with people more than working with objects, machines, or information.',
      'You also enjoy teaching, giving advice helping and being of service to people.',
    ],
  },
];

export const valuesDimensions = [
  {
    key: 'commitment',
    name: 'Commitment',
    description: { positive: ["You're committed"], negative: ["You're not committed"] },
  },
  {
    key: 'excellence',
    name: 'Excellence',
    description: { positive: ["You're excellent"], negative: ["You're not excellent"] },
  },
  {
    key: 'hierarchy',

    name: 'Hierarchy',
    description: { positive: ['You value hierarchy'], negative: ["You don't value hierarchy"] },
  },
  {
    key: 'resilience',

    name: 'Resilience',
    description: { positive: ["You're resilient"], negative: ["You're not resilient"] },
  },
  {
    key: 'team_player',

    name: 'Team Player',
    description: { positive: ["You're a team player"], negative: ["You're not a team player"] },
  },
];

export const resultsDimensions = [...personalityDimensions, ...vocationalInterests, ...valuesDimensions];
