import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ProfileInput } from '__generated__/graphql';
import { isPostcodeValid, isValidEmail } from 'components/fields/validators';
import { RegionSelectField } from 'components/fields/RegionSelectField';
import { InputField, CheckboxField, JobRoleSelectField, DateField, Loading } from 'components';
import { useNextStep } from 'hooks/useStep';
import { useUser } from '../context/userContext';

interface ProfileForm extends ProfileInput {
  nameConsentGiven: boolean;
}

const Profile = () => {
  const { saveProfile, profile, userLoading, company, companyLoading } = useUser();
  const { nextStep } = useNextStep();
  const companyId = company?.id || '';

  const methods = useForm<ProfileForm>({
    defaultValues: {
      ...profile,
      companyId,
      nameConsentGiven: false,
    },
    mode: 'onChange',
  });
  const { handleSubmit, reset, watch } = methods;

  useEffect(() => {
    reset({ ...profile, companyId });
  }, [reset, profile, companyId]);

  if (userLoading || companyLoading) return <Loading />;

  const onSubmit = (data: ProfileForm) => {
    saveProfile({
      canWorkUnsociableHours: data.canWorkUnsociableHours,
      canWorkWeekends: data.canWorkWeekends,
      dateOfEmployment: data.dateOfEmployment,
      isHired: data.isHired,
      email: data.email,
      hasCar: data.hasCar,
      companyId: data.companyId,
      jobRoleId: data.jobRoleId,
      hasDrivingLicense: data.hasDrivingLicense,
      isNightDriver: data.isNightDriver,
      isRuralDriver: data.isRuralDriver,
      firstName: data.firstName,
      surname: data.surname,
      postcode: data.postcode,
      regionApplied: data.regionApplied,
    });

    nextStep();
  };
  const consentValidator = (value: boolean) => {
    if (value === true) {
      return true;
    } else {
      return 'Please provide consent to do survey';
    }
  };

  const isHired = watch('isHired');

  return (
    <>
      <div>
        <div className="text-white text-xl sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">Your Profile</div>
        <p className="text-white">Tell us about yourself below</p>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-8 sm:mt-12 w-full">
            <InputField name="firstName" isRequired label="First name" placeholder="First name" />
            <InputField name="surname" isRequired label="Surname" placeholder="Surname" />
            <InputField name="email" isRequired label="Email" placeholder="Enter your email" validate={isValidEmail} />
            <InputField name="postcode" isRequired label="Postcode" placeholder="Enter your postcode" validate={isPostcodeValid} />
            <JobRoleSelectField name="jobRoleId" label="Job Role" companyId={companyId} />
            <RegionSelectField name="regionApplied" label="Region" company={company} />
            <CheckboxField name="isHired" label="Are you a current employee?" />
            {isHired && <DateField label="When did you start with the company" name="dateOfEmployment" />}
            <CheckboxField name="hasDrivingLicense" label="I have a driving license" />
            <CheckboxField name="hasCar" label="I have a car" />
            <CheckboxField name="isNightDriver" label="I can drive in the dark" />
            <CheckboxField name="isRuralDriver" label="I can drive on rural roads" />
            <CheckboxField name="canWorkWeekends" label="I can work weekends" />
            <CheckboxField name="canWorkUnsociableHours" label="I can work unsociable hours" />
            <div className="h-px w-full bg-gray-100 my-6" />

            <CheckboxField
              name="nameConsentGiven"
              validate={consentValidator}
              label="I consent to the use of my data to assess personality and value alignment as part of the recruitment process."
            />
          </div>

          <button type="submit" className="text-black bg-yellow-500 rounded-lg px-5 py-2.5 font-semibold text-md leading-md">
            Start the Quiz
          </button>
        </form>
      </FormProvider>
    </>
  );
};

export default Profile;
