import { gql, useQuery } from '@apollo/client';
import { Company, CompanySearch } from '__generated__/graphql';

export const GET_COMPANY = gql`
  query GetCompany($query: CompanySearch) {
    GetCompany(query: $query) {
      id
      name
      active
      commute_time_configs {
        id
        address_destination
        profile_filter_regex
        profile_filter_value
        profile_value
        title
        type
        active
      }
    }
  }
`;

interface GetCompanyResponse {
  GetCompany: Company;
}

export const useGetCompany = (query: CompanySearch) => {
  const { loading, data } = useQuery<GetCompanyResponse>(GET_COMPANY, {
    variables: {
      skip: !query.id,
      query: {
        ...query,
      },
    },
  });
  return {
    companyLoading: loading,
    company: data?.GetCompany,
  };
};
