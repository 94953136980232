import { useQuery, gql, useMutation } from '@apollo/client';
import { SurveyAnswer, Maybe, AnswerTimesInput, AnswersItemInput, ProfileInput } from '__generated__/graphql';

const SAVE_SURVEY_ANSWERS = gql`
  mutation SaveSurveyAnswers($input: SaveSurveyAnswersInput) {
    SaveSurveyAnswers(input: $input) {
      id
      message
      date
    }
  }
`;

export const GET_SURVEY_ANSWERS = gql`
  query GetSurveyAnswers($query: SurveyAnswerSearch) {
    GetSurveyAnswers(query: $query) {
      id
      email
      profile {
        firstName
        surname
        email
        postcode
        dateOfEmployment
        isHired
        hasDrivingLicense
        hasCar
        jobRoleId
        companyId
        isNightDriver
        isRuralDriver
        canWorkWeekends
        canWorkUnsociableHours
        regionApplied
      }
      answers {
        p15a
        p14
        p12
        p17
        p13
        p1a
        p10
        p11
        p5
        p19a
        p16a
        p6
        p20b
        p2
        p7
        p18
        p3
        p8
        p4
        p9
        ip23
        ip9
        ip15
        ip3
        ip18
        ip12
        ip19
        ip5
        ip7
        ip29
        ip27
        ip21
        ip4
        ip14
        ip2
        ip8
        ip16
        ip28
        ip11
        ip13
        ip26
        ip25
        ip22
        ip10
        ip1
        ip24
        ip17
        ip6
        ip30
        ip20
        yv33
        yv5
        yv4
        yv23
        yv34
        yv2
        yv26
        yv28
        yv29
        yv37
        yv12
        yv21a
        yv38
        yv32
        yv8
        c24a
        c25
        c19
        c7
        c13
        c17
        c16
        hi3
        c23
        c24
        c14
        dt4
        c10
        c4
        c22
      }
    }
  }
`;

interface GetSurveyAnswersResponse {
  GetSurveyAnswers: Maybe<SurveyAnswer>;
}

const useGetSurveyAnswers = (id: string, companyId: string) => {
  const { data, loading, error, refetch } = useQuery<GetSurveyAnswersResponse>(GET_SURVEY_ANSWERS, {
    variables: {
      query: {
        id,
        companyId,
      },
    },
  });
  const userData = data?.GetSurveyAnswers;
  return {
    id: userData?.id,
    answerTimes: userData?.answerTimes || {},
    answers: userData?.answers || {},
    profile: userData?.profile || {},
    userLoading: loading,
    errorUser: error,
    refetch,
  };
};

const useSaveAnswers = (id: string | null, companyId: string) => {
  const saveProfile = (profile: ProfileInput) => {
    saveAnswers({
      variables: {
        input: { id, companyId, profile },
      },
    });
  };

  const saveUserAnswers = (answers: AnswersItemInput, answerTimes: AnswerTimesInput) => {
    saveAnswers({
      variables: {
        input: { id, companyId, answers, answerTimes },
      },
    });
  };

  const saveCompleteProcess = () => {
    saveAnswers({
      variables: {
        input: { id, companyId, reportingStatus: 'ready_for_reporting' },
      },
    });
  };

  const [saveAnswers, { loading: saveAnswersLoading, error: saveAnswersError }] = useMutation(SAVE_SURVEY_ANSWERS, {
    refetchQueries: [
      {
        query: GET_SURVEY_ANSWERS,
        variables: {
          query: {
            id,
            companyId,
          },
        },
      },
    ],
  });

  return {
    saveProfile,
    saveUserAnswers,
    saveCompleteProcess,
    saveAnswersLoading,
    saveAnswersError,
  };
};

export { useGetSurveyAnswers, useSaveAnswers };
