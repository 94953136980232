import { useState, useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { PolarAngleAxis, RadialBar, RadialBarChart } from 'recharts';
import { MdOutlineFileDownload } from 'react-icons/md';

import ResultsInfoCarousel from 'components/ResultsInfo/ResultsInfoCarousel';
import ResultsInfoTabs from 'components/ResultsInfo/ResultsInfoTabs';
import ResultsInfoContainer from 'components/ResultsInfo/ResultsInfoContainer';
import ResultsInfoList from 'components/ResultsInfo/ResultsInfoList';
import ResultsInfoCarouselPagination from 'components/ResultsInfo/ResultsInfoCarouselPagination';
import PrintableResults from 'components/PrintableResults/PrintableResults';
import { Loading } from 'components';
import { useUser } from 'context/userContext';
import { computeCategoryTraits, createOutputDescription } from './utils';
import { INFO_LIST_COLORS, resultsDimensions } from './constants';
import { CATEGORY_TRAITS, TraitCategory } from './types';

const resultsTabNames: string[] = Object.keys(CATEGORY_TRAITS);

const ResultsOverview = () => {
  // Change activeTab to be of type TraitCategory
  const [activeTab, setActiveTab] = useState<TraitCategory>(resultsTabNames[0] as TraitCategory);

  const [currentIndex, setCurrentIndex] = useState(0);

  const { answers, questions, userLoading, questionLoading } = useUser();

  const contentRef = useRef<HTMLDivElement>(null);

  const reactToPrintFn = useReactToPrint({ contentRef, ignoreGlobalStyles: true });

  // Reset the index when activeTab changes
  useEffect(() => {
    setCurrentIndex(0);
  }, [activeTab]);

  // const updateContainerWidth = (index: number, width: number) => {
  //   setContainerWidths((prev) => {
  //     if (prev[index] === width) return prev; // Avoid unnecessary updates
  //     const updatedWidths = [...prev];
  //     updatedWidths[index] = width;
  //     return updatedWidths;
  //   });
  // };

  const computedTraits = computeCategoryTraits(activeTab, answers, questions[activeTab] || []);

  // Create a lookup map to avoid redundant searches
  const resultsDimensionMap = new Map(resultsDimensions.map((dimension) => [dimension.key, dimension]));

  const resultsData = resultsDimensions
    .filter(({ key }) => key in computedTraits)
    .map(({ key }, index) => {
      const resultsDimension = resultsDimensionMap.get(key);

      const name = resultsDimension?.name ?? '';
      const value = computedTraits[key as keyof typeof computedTraits];
      const description = resultsDimension?.description ?? [''];

      return {
        name,
        description,
        value,
        fill: INFO_LIST_COLORS[index],
      };
    });

  const handleClickTab = (tabName: string) => setActiveTab(tabName as TraitCategory);

  if (userLoading || questionLoading) return <Loading />;

  return (
    <div className="relative h-full">
      <div className="flex items-center justify-between w-full h-16 px-4 ">
        {/* Title */}
        <h2 className="mx-auto text-2xl font-bold text-center text-white">Your Results</h2>
      </div>

      {/* Tabs */}
      <ResultsInfoTabs tabNames={resultsTabNames} activeTab={activeTab} onClick={handleClickTab} />
      {/* Carousel */}
      {resultsData.length ? (
        <>
          <ResultsInfoCarousel /* containerwidths={containerWidths} */ currentIndex={currentIndex} setCurrentIndex={setCurrentIndex}>
            <ResultsInfoContainer>
              <div className="mb-4 text-lg text-center text-white md:text-sm md:my-8">This is how we would describe your {activeTab}:</div>
              <ResultsInfoList resultsData={resultsData} />
            </ResultsInfoContainer>
            {resultsData.map((data, index) => {
              const { name, description, value } = data;
              return (
                <ResultsInfoContainer key={name}>
                  <RadialBarChart
                    className="mt-20"
                    width={120}
                    height={120}
                    innerRadius={50}
                    outerRadius={120}
                    startAngle={90}
                    endAngle={-270}
                    barSize={12}
                    data={[data]}
                  >
                    <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
                    <RadialBar label={false} background={{ fill: '#0D1C1C' }} dataKey="value" cornerRadius="5" />
                  </RadialBarChart>
                  <div className="mt-6 text-xl font-semibold text-white">{name}</div>
                  <div className="mt-6 text-sm text-center text-white">{createOutputDescription(name, description, value)}</div>
                </ResultsInfoContainer>
              );
            })}
          </ResultsInfoCarousel>
          <hr className="block w-full md:hidden" />

          {/* Carousel Pagination */}
          <div className="my-6 md:my-0">
            <ResultsInfoCarouselPagination pageCount={resultsData.length + 1} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
          </div>
        </>
      ) : (
        // Message to display if no data present
        <div className="flex items-center justify-center w-full">
          <span className="text-xl text-white">{`No ${activeTab} data`}</span>
        </div>
      )}

      {/* Download Results Button */}
      <div className="flex flex-row justify-center">
        <button
          className="absolute flex items-center justify-center w-5/6 h-10 px-4 py-6 space-x-1 translate-y-0 rounded-md sm:translate-y-2 sm:mr-8 sm:top-0 sm:right-0 bg-downloadButton sm:w-fit"
          type="button"
          onClick={() => reactToPrintFn()}
        >
          <MdOutlineFileDownload className="text-3xl" /> <span className="font-medium">Download results</span>
        </button>
      </div>

      <PrintableResults contentRef={contentRef} answers={answers} questions={questions} />
    </div>
  );
};

export default ResultsOverview;
