import { useGetJobRoles } from 'api/hooks/useJobRole';
import { SelectField } from './SelectField';

interface JobRoleSelectFieldProps {
  label: string;
  name: string;
  disabled?: boolean;
  testId?: string;
  searchable?: boolean;
  companyId: string;
}

const JobRoleSelectField = ({ label, name, disabled = false, searchable, testId, companyId }: JobRoleSelectFieldProps) => {
  const { jobRoles, jobRoleLoading } = useGetJobRoles({ companyId });
  const jobRoleOptions = jobRoles?.map((s, index) => {
    return { label: s.name, value: s.id, id: index.toString() };
  });

  return (
    <SelectField
      isRequired={true}
      loading={jobRoleLoading}
      searchable={searchable}
      testId={testId}
      label={label}
      name={name}
      options={jobRoleOptions || []}
      disabled={disabled}
    />
  );
};

export { JobRoleSelectField };
