import { useNavigate, useLocation, useParams } from 'react-router-dom';

export const useNextStep = (): { nextStep: () => void } => {
  const location = useLocation();
  const navigate = useNavigate();
  const { companyId } = useParams();

  const steps = [
    `/${companyId}`,
    `/${companyId}/profile`,
    `/${companyId}/questions/personality`,
    `/${companyId}/questions/interests/instructions`,
    `/${companyId}/questions/interests`,
    `/${companyId}/questions/values/instructions`,
    `/${companyId}/questions/values`,
    `/${companyId}/questions/customer/instructions`,
    `/${companyId}/questions/customer`,
    `/${companyId}/results-intro`,
    // `/${companyId}/personality-results`,
    // `/${companyId}/interests-results`,
    // `/${companyId}/thank-you`,
  ];

  // the two steps removed are no longer used due to removing the id from local storage, maybe we can
  // move the contents of those pages to the thank you page.

  const currentIndex = steps.indexOf(location.pathname);
  let nextIndex = currentIndex + 1;

  if (nextIndex >= steps.length) {
    nextIndex = 0;
  }

  const nextStep = () => {
    navigate(steps[nextIndex]);
  };
  return { nextStep };
};
