import { Navigate, Outlet } from 'react-router-dom';
import { Suspense } from 'react';
import { useUser } from 'context/userContext';

function Layout() {
  const { company, companyLoading } = useUser();

  if (!companyLoading && (!company || !company?.active)) {
    return <Navigate to="/not-found" replace />;
  }

  return (
    <div className="flex flex-col min-h-screen bg-primary-600">
      <div className="flex-grow py-10">
        <Suspense fallback={null}>
          {/* <div className="px-4 md:px-[5%]"> */}
          <Outlet />
          {/* </div> */}
        </Suspense>
      </div>
      <footer>
        <div className="bottom-0 left-0 right-0 w-full max-w-screen-xl p-4 mx-auto md:py-8 fix">
          <span className="block text-white text-md sm:text-center dark:text-gray-400">© 2024 Teamfit. All Rights Reserved.</span>
        </div>
      </footer>
    </div>
  );
}

export default Layout;
