import React, { useEffect, useState } from 'react';

import useViewportWidth from 'hooks/useViewportWidth';

interface ResultsInfoCarouselProps {
  children: React.ReactNode;
  // containerwidths: number[];
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
}

const ResultsInfoCarousel = ({ children, /* containerwidths, */ currentIndex, setCurrentIndex }: ResultsInfoCarouselProps) => {
  const childrenArray = React.Children.toArray(children);
  const [startX, setStartX] = useState<number | null>(null);
  const [isSwiping, setIsSwiping] = useState(false);

  const [containerWidth, setContainerWidth] = useState<number>(650);

  const vieportWidth = useViewportWidth();

  useEffect(() => {
    if (vieportWidth <= 768) {
      setContainerWidth(vieportWidth * (5 / 6));
    } else {
      setContainerWidth(650);
    }
  }, [vieportWidth]);

  const calculateShift = () => {
    const viewportHalf = vieportWidth / 2; // Half of viewport width (50vw)
    const containerHalf = containerWidth / 2; // Half of container width
    const gap = 8; // Space between containers (space-x-2 = 8px gap)

    return viewportHalf - containerHalf - currentIndex * (containerWidth + gap);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setStartX(e.touches[0].clientX);
    setIsSwiping(false);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (startX === null) return;
    const deltaX = e.touches[0].clientX - startX;

    // Detect if the user is swiping
    if (Math.abs(deltaX) > 20) {
      setIsSwiping(true);
    }
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    if (!isSwiping || startX === null) return;

    const endX = e.changedTouches[0].clientX;
    const deltaX = endX - startX;

    // Determine swipe direction
    if (deltaX > 50 && currentIndex > 0) {
      // Swipe right (go to previous slide)
      setCurrentIndex(currentIndex - 1);
    } else if (deltaX < -50 && currentIndex < childrenArray.length - 1) {
      // Swipe left (go to next slide)
      setCurrentIndex(currentIndex + 1);
    }

    // Reset swipe state
    setStartX(null);
    setIsSwiping(false);
  };

  return (
    <div className="w-screen overflow-hidden" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
      {/* Results Info Containers */}
      <div
        className="flex flex-row space-x-2 transition ease-out delay-75 w-max"
        style={{
          transform: `translateX(${calculateShift()}px)`,
        }}
      >
        {childrenArray.map((child, index) => (
          <div key={index} style={{ width: containerWidth }}>
            {child}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResultsInfoCarousel;
