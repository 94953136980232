import { gql, useQuery } from '@apollo/client';
import { JobRole, JobRoleSearch } from '__generated__/graphql';

export const GET_JOB_ROLES = gql`
  query GetJobRoles($query: JobRoleSearch) {
    GetJobRoles(query: $query) {
      id
      name
    }
  }
`;

interface GetJobRolesResponse {
  GetJobRoles: JobRole[];
}

const useGetJobRoles = (query: JobRoleSearch) => {
  const { loading, data, error, refetch } = useQuery<GetJobRolesResponse>(GET_JOB_ROLES, {
    variables: {
      skip: !query.companyId,
      query: {
        ...query,
      },
    },
  });

  return {
    jobRoles: data?.GetJobRoles,
    jobRoleLoading: loading,
    errorJobRole: error,
    refetch,
  };
};

export { useGetJobRoles };
