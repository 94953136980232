import { ReactNode } from 'react';
import { RESULTS_INFO_CONTAINER_DIMS } from 'pages/ResultsOverview/constants';

interface ResultsInfoContainerProps {
  children: ReactNode;
  // updateWidth: (width: number) => void;
}

const ResultsInfoContainer = ({ children }: ResultsInfoContainerProps) => {
  return (
    <div
      // ref={containerRef}
      className="flex flex-col items-center p-8 mt-8 rounded-b-none md:rounded-3xl rounded-[48px] bg-infoBox md:my-12"
      style={{ height: RESULTS_INFO_CONTAINER_DIMS.height }}
    >
      {children}
    </div>
  );
};

export default ResultsInfoContainer;
